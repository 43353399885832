<template>
  <div id="app">
    <Layout/>
  </div>
</template>

<script>
import Layout from './layout'

export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
