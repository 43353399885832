<template>
    <div id="layout">
       <router-view ></router-view> 
    </div>
</template>

<script>
export default {
}
</script>

<style>
    /* 重置样式 */
    @import url(../assets/css/index.css);
    /* 公共样式 */
    @import url(../assets/css/common.css);
</style>