import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    linkActiveClass:'activateStyle_',
    mode: 'history',
    routes:[
        {
            path: '/',
            // redirect: '/home_marriage_test' //重定向
            redirect: '/home_marriage_test_v2' //重定向
        },
        {
            path: '/home_marriage_test', 
            name:'homeMarriageTest',
            component: () => import('../pages/home_marriage_test'), //婚姻首页
            meta:{
                title:'首页',
                
                allowBack: false
            },
        },
       
        {
          //type 1支付保 2微信 10未知
            path: '/home_marriage_test/payment/:id/:type', //支付页
            name:'homeMarriageTestPayment',
            component: () => import('../pages/home_marriage_test/payment.vue'), //支付页
            meta:{
                title:'支付页',
                keep_alive:true,
                popUp_fal:true,//弹窗
            },
        },
        {
            path: '/home_marriage_test/payment/succeed/:id/:type', //支付成功页
            name:'homeMarriageTestPaymentSucceed',
            component: () => import('../pages/home_marriage_test/succeed.vue'), //支付成功页
            // component: () => import('../pages/home_marriage_test/succeedCopy.vue'), //支付成功页 废弃
            meta:{
                title:'支付成功页'
            },
        },
        {
            path: '/home_marriage_test/payment/usersBinding', //支付成功姓名绑定页
            name:'homeMarriageTestPaymentusersBinding',
            component: () => import('../pages/home_marriage_test/usersBinding.vue'), //支付成功姓名绑定页
            meta:{
                title:'支付成功页'
            },
        },
       
        {
            path: '/home_marriage_test/payment/result/:id/:type', //支付结果页
            name:'homeMarriageTestPaymentResult',
            // redirect:'/home_marriage_test/payment/result/:id/:type/information',
            // component: () => import('../pages/home_marriage_test/resultCopy.vue'), //支付结果页
            component: () => import('../pages/home_marriage_test/result.vue'), //支付结果页
            meta:{
                title:'支付结果页开发版'
            },
            children:[
              {
                path: 'information',
                name:'homeMarriageTestPaymentResultInformation',
                component: () => import('../pages/home_marriage_test/result_content/information.vue'), 
                meta:{
                  title:'基本信息',
                  active_fal:true,
                  pagination:1,
                },
              },
              {
                path: 'analysis',
                name:'homeMarriageTestPaymentResultAnalysis',
                component: () => import('../pages/home_marriage_test/result_content/analysis.vue'), 
                meta:{
                  title:'婚姻分析',
                  active_fal:true,
                  pagination:2,
                },
              },
              {
                path: 'suggest',
                name:'homeMarriageTestPaymentResultSuggest',
                component: () => import('../pages/home_marriage_test/result_content/suggest.vue'), 
                meta:{
                  title:'老师建议',
                  active_fal:true,
                  pagination:3,
                },
              },
              {
                path: 'increase',
                name:'homeMarriageTestPaymentResultIncrease',
                component: () => import('../pages/home_marriage_test/result_content/increase.vue'), 
                meta:{
                  title:'增运方式',
                  active_fal:true,
                  pagination:4,
                },
              },
            ]
        },

        {
          path: '/home_marriage_test_v2', 
          name:'homeMarriageTestV2',
          component: () => import('../pages/home_marriage_test_v2'), //婚姻首页第二版
          meta:{
              title:'首页'
          },
        },

        {
          //type 1支付保 2微信 10未知
            path: '/home_marriage_test_v2/payment/:id/:type', //支付页第二版
            name:'homeMarriageTestV2Payment',
            component: () => import('../pages/home_marriage_test_v2/payment.vue'), //支付页第二版
            meta:{
                title:'支付页',
                keep_alive:true,
                popUp_fal:true,//弹窗
            },
        },
        {
          path: '/home_marriage_test_v3', 
          name:'homeMarriageTestV3',
          component: () => import('../pages/home_marriage_test_v3'), //婚姻首页第三版
          meta:{
              title:'首页'
          },
        },

        {
          //type 1支付保 2微信 10未知
            path: '/home_marriage_test_v3/payment/:id/:type', //支付页第三版
            name:'homeMarriageTestV3Payment',
            component: () => import('../pages/home_marriage_test_v3/payment.vue'), //支付页第三版
            meta:{
                title:'支付页',
                keep_alive:true,
                popUp_fal:true,//弹窗
            },
        },

        //·················································································································································
        //姓名测试
        {
          path: '/name_matching', 
          name:'nameMatching',
          component: () => import('../pages/name_matching'), //姓名测试首页
          meta:{
              title:'姓名测试首页'
          },
        },
        {
          path: '/name_matching/payment/:id/:type', 
          name:'nameMatchingPayment',
          component: () => import('../pages/name_matching/payment.vue'), //姓名测试支付页
          meta:{
              title:'姓名测试支付页'
          },
        },
        {
          path: '/name_matching/payment/succeed/:id/:type', //支付成功页
          name:'nameMatchingTestPaymentSucceed',
          component: () => import('../pages/name_matching/succeed.vue'), //支付成功页
          meta:{
              title:'支付成功页'
          },
      },


        {
          path: '/name_matching/payment/result/:id/:type', 
          name:'nameMatchingPaymentResult',
          // redirect:'/name_matching/payment/result/:id/:type/information',
          // component: () => import('../pages/name_matching/resultCopy.vue'), //姓名测试结果页
          component: () => import('../pages/name_matching/result.vue'), //姓名测试结果页
          meta:{
              title:'姓名测试结果页新版'
          },
          children:[
              {
                path: 'information',
                name:'nameMatchingPaymentResultPaymentResultInformation',
                component: () => import('../pages/name_matching/result_content/information.vue'), 
                meta:{
                  title:'基本信息',
                  active_fal:true,
                  pagination:1,
                },
              },
              {
                path: 'analysis',
                name:'nameMatchingPaymentResultPaymentResultAnalysis',
                component: () => import('../pages/name_matching/result_content/analysis.vue'), 
                meta:{
                  title:'婚姻分析',
                  active_fal:true,
                  pagination:2,
                },
              },
              {
                path: 'suggest',
                name:'nameMatchingPaymentResultPaymentResultSuggest',
                component: () => import('../pages/name_matching/result_content/suggest.vue'), 
                meta:{
                  title:'老师建议',
                  active_fal:true,
                  pagination:3,
                },
              },
              {
                path: 'increase',
                name:'nameMatchingPaymentResultPaymentResultIncrease',
                component: () => import('../pages/name_matching/result_content/increase.vue'), 
                meta:{
                  title:'增运方式',
                  active_fal:true,
                  pagination:4,
                },
              },
            ]
        },

         //·················································································································································
        //流年运势
        {
          path: '/fleeting_time_fortune', 
          name:'fleetingTimeFortune',
          component: () => import('../pages/fleeting_time_fortune'), //流年运势首页
          meta:{
              title:'流年运势首页',
              top_title:'虎年运势'
          },
        },
        {
          path: '/fleeting_time_fortune/payment/:id/:type', 
          name:'fleetingTimeFortunePayment',
          component: () => import('../pages/fleeting_time_fortune/payment.vue'), //流年运势支付页面
          meta:{
              title:'流年运势支付页面',
              top_title:'虎年运势'
          },
        },
        {
          path: '/fleeting_time_fortune/payment/result/:id/:type', 
          name:'fleetingTimeFortunePaymentResult',
          component: () => import('../pages/fleeting_time_fortune/result.vue'), //流年运势结果页面
          meta:{
              title:'流年运势结果页面',
              top_title:'虎年运势'
          },
        },

        //............................................................................................................................................
        //小程序跳转页面
        {
          path: '/mini_program_page/result', //支付结果页
          name:'mini_program_pageResult',
          component: () => import('../pages/mini_program_page/result_artificial.vue'), //支付结果页
          meta:{
              title:'支付结果页'
          },
        },
        {
          path: '/mini_program_page/hot_recommend/:id', //热门推荐 id === 1 是热门推荐1； id === 2 是热门推荐2；
          name:'miniProgramPageHotRecommend',
          component: () => import('../pages/mini_program_page/hot_recommend.vue'), //热门推荐
          meta:{
              title:'热门推荐'
          },
        },
        {
          path: '/mini_program_page/leave_message', //留言
          name:'miniProgramPageLeaveMessage',
          component: () => import('../pages/mini_program_page/leave_message.vue'), //留言
          meta:{
              title:'留言'
          },
        },
        {
          path: '/mini_program_page/course', //易学大讲堂
          name:'miniProgramPageCourse',
          component: () => import('../pages/mini_program_page/course.vue'), //易学大讲堂
          meta:{
              title:'易学大讲堂'
          },
        },
        {
          path: '/mini_program_page/course_succeed', //易学大讲堂支付结果页
          name:'miniProgramPageCourseSucceed',
          component: () => import('../pages/mini_program_page/course_succeed.vue'), //易学大讲堂支付结果页
          meta:{
              title:'易学大讲堂支付结果页'
          },
        },
        
    ]
})

router.beforeEach( (to, from, next) => {
  window.scrollTo(0, 0)  
    
    let query = {...to.query}

    let uniqueKey = window.localStorage.getItem('uniqueKey');
    if(uniqueKey){
      query.uniqueKey = uniqueKey
    }

    let querysgtr = window.localStorage.getItem('query1');

    if(query){
      let querydata = JSON.parse(querysgtr);
      query = Object.assign(query,querydata);
    }
    if (to.meta.top_title) {
      document.title = to.meta.top_title;
    }

    if (!to.query.from) {
      let fromk= window.localStorage.getItem('fromk')? window.localStorage.getItem('fromk') :10;
      query.from = to.query.from || from.query.from || fromk ;
 
      next({...to, query})
    }else if(uniqueKey && !to.query.uniqueKey){
      next({...to, query})
    }else if(querysgtr && !to.query.appkey){
      next({...to, query})
    }else{
      next();
    }
  
})

export default router