import Vue from 'vue'
import App from './App.vue'
import router from "./routes/index"
import "./utils/rem"
import 'vant/lib/index.css'

import { Button,Form,Field,RadioGroup, Radio,Picker,Popup, Swipe, SwipeItem,Toast,Rate,Row, Col,CountDown,Dialog,Overlay,Loading,Checkbox, CheckboxGroup} from 'vant';
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Rate);
Vue.use(Row);
Vue.use(Col);
Vue.use(CountDown);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
